import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import SEO from '../components/SEO.js';
import AppTheme from '../AppTheme.js';
import FullScreenImage from '../components/FullScreenImage.js';

const useStyle = makeStyles(theme => ({
  background: {
    backgroundColor: '#58361d',
  },
}));

const DaniDen = () => {

  const classes = useStyle();

  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "victoria-tronina-L0RqBLzQq2c-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "victoria-tronina-L0RqBLzQq2c-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={AppTheme}>
        <SEO title="daniden" description="I'm poppy." />
        <FullScreenImage
          fluid={sources}
          credit="Photo by Victoria Tronina on Unsplash"
          backgroundClassName={classes.background}
        />
      </ThemeProvider>
    </>
  );
};

export default DaniDen;
